import React from "react";
import classNames from "classnames";

const Section = ({ title, className, actions, children }) => {
    const computedClassName = Array.isArray(className)
        ? className.map((className) => `section--${className}`).join(" ")
        : `section--${className}`;
    return (
        <section
            className={classNames("section", {
                "section--with-actions": actions,
                [computedClassName]: className
            })}
        >
            <div className="section__head container">
                <div className="section__title">{title}</div>
                {actions && <div className="section__actions">{actions}</div>}
            </div>
            <div className="section__content">{children}</div>
        </section>
    );
};

export default Section;
