import React, {useMemo} from "react";
import Section from "../../../../../components/organisms/Section";
import { useLocation } from "react-router";
import ProductListGrid from "theme/modules/ProductList/ProductListGrid";
import MediaQuery from "theme/components/helpers/MediaQuery";
import Breakpoints from "theme/components/atoms/Breakpoints";

const WidgetMerchandising = ({ data, attributes }) => {
    const { products, imageType } = data;
    const location = useLocation();
    const { is_mobile_visible, is_desktop_visible } = attributes;

    const productsInStock = useMemo(
        () =>
            products
                ?.filter((item) => item?.stock?.isInStock === true)
                .map((productsInStock) => {
                    let selectedImage = imageType.find(
                        (item) => item.product_id === parseInt(productsInStock.entity_id)
                    )?.type;
                    selectedImage = selectedImage || "S";

                    productsInStock.images.forEach((image) => {
                        let imageName = image.file;
                        if (
                            imageName.search(
                                productsInStock.kpl_parent_link + "_" + selectedImage
                            ) > -1
                        ) {
                            productsInStock.imageUrl = imageName;
                        }
                    });
                    return productsInStock;
                }),
        [imageType, products]
    );
    if (productsInStock.length === 0 ) return null;

    return (
        <div className="block-merchandising">
            <Section>
            {
                is_desktop_visible === '1' &&
                <MediaQuery minWidth={Breakpoints.tablet + 1}>
                    <ProductListGrid
                        length={productsInStock.length}
                        numberOfProductsPerLine={2}
                        products={productsInStock}
                        location={location}
                        productItemProp="product"
                        imageSizes={"small"}
                        itemClass={"merchandising"}
                    />
                </MediaQuery>
            }
            {
                is_mobile_visible === '1' &&
                <MediaQuery maxWidth={Breakpoints.tablet}>
                    <ProductListGrid
                        length={productsInStock.length}
                        numberOfProductsPerLine={2}
                        products={productsInStock}
                        location={location}
                        productItemProp="product"
                        imageSizes={"small"}
                        itemClass={"merchandising"}
                    />
                </MediaQuery>
            }
            </Section>
        </div>
    );
};

export default WidgetMerchandising;