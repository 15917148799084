import React, { useEffect } from "react";
import { useSliderState } from "theme/components/organisms/Slider/Slider";
import ProductItem from "theme/modules/ProductView/ProductItem";
import ProductItemSkeleton from "theme/modules/ProductView/ProductItem/ProductItemSkeleton";
import { Grid } from "front-commerce/theme-chocolatine/web/theme/components/templates/Grid";
import Cell from "../../../components/templates/Grid/Cell";
import Spinner from "front-commerce/src/web/theme/components/atoms/Spinner";

const ProductListGrid = ({
  loading,
  fetchMore,
  length,
  numberOfProductsPerLine,
  products,
  location,
  productItemProp,
  imageSizes,
  prioritizeTop = 0,
  itemClass,
}) => {
  const sliderProps = useSliderState(
    Math.ceil(length / numberOfProductsPerLine)
  );
  useEffect(() => {
    if (fetchMore) {
      fetchMore(sliderProps.state.nextIndex);
    }
  }, [fetchMore, sliderProps.state.nextIndex]);

  return loading ? (
    <Grid>
      <Spinner />
    </Grid>
  ) : (
    <Grid>
      {products
        .map((product, index) => {
          return (
            <Cell key={product.sku} size={["desktop-24", "mobile-44"]}>
              <ProductItem
                key={product.sku}
                product={product}
                products={products}
                location={location}
                index={index}
                itemProp={productItemProp}
                imageSizes={imageSizes}
                imagePriority={index < prioritizeTop}
                className={itemClass}
                EnableProductAction
                enableBadges
              />
            </Cell>
          );
        })
        .concat(
          new Array(Math.max(0, length - products.length))
            .fill(null)
            .map((_, index) => {
              return <ProductItemSkeleton key={`empty-${index}`} />;
            })
        )}
    </Grid>
  );
};

export default ProductListGrid;
